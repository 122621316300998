<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			id="exportTab"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			height="60vh"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
		>
			<el-table-column prop="id" label="id" width="80px"> </el-table-column>
			<el-table-column prop="title" label="banner标题" show-overflow-tooltip>
			</el-table-column>
			<el-table-column label="Banner展示位" width="160px">
				<template slot-scope="scope">
					<span v-if="scope.row.type == 1">首页顶部banner</span>
					<span v-if="scope.row.type == 2">专栏页顶部banner</span>
					<span v-if="scope.row.type == 3">活动首页顶部banner</span>
					<span v-if="scope.row.type == 4">专题顶部banner</span>
					<span v-if="scope.row.type == 5">香港专区顶部banner</span>
					<span v-if="scope.row.type == 6">美国专区顶部banner</span>
				</template>
			</el-table-column>
			<el-table-column prop="desc" label="描述" width="160px">
			</el-table-column>
			<el-table-column prop="create_time" label="创建时间" width="150px">
			</el-table-column>
			<el-table-column prop="update_time" label="更新时间" width="150px">
			</el-table-column>
			<el-table-column label="上架状态" width="80px">
				<template slot-scope="scope">
					<span v-if="scope.row.is_show == 1">已上架</span>
					<span v-else>未上架</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="250px">
				<template slot-scope="scope">
					<span
						class="pointer margin_left_10"
						@click="disBannerShow(scope.row)"
					>
						{{ scope.row.is_show == 1 ? "操作下架" : "操作上架" }}
					</span>
					<span class="pointer margin_left_10" @click="toEdit(scope.row)">
						修改
					</span>
					<span class="pointer margin_left_10" @click="toDelete(scope.row)">
						删除
					</span>
				</template>
			</el-table-column>
		</el-table>

		<div v-if="loading" class="loading-overlay">
			<div class="spinner"></div>
		</div>
	</div>
</template>

<script>
import { bannerUpdate } from "@/api/article";
import { addPush } from "@/api/user";
import clipboard from "clipboard";
import ChineseConv from "chinese-s2t";

export default {
	props: ["tableData"],
	name: "",
	data() {
		return {
			value: true,
			loading: false,
		};
	},
	methods: {
		disBannerShow(row) {
			this.loading = true;
			bannerUpdate({ id: row.id, is_show: row.is_show == 1 ? 0 : 1 }).then(
				(res) => {
					this.loading = false;
					if (res.code == 200) {
						this.$parent.$parent.getDataList();
					}
				}
			);
		},
		toDelete(row) {
			this.loading = true;
			bannerUpdate({ id: row.id, is_delete: 1 }).then((res) => {
				this.loading = false;
				if (res.code == 200) {
					this.$parent.$parent.getDataList();
				}
			});
		},
		toEdit(row) {
			this.$router.push({
				path: "/recommend/bannerdetail",
				query: { id: row.id, type: "edit" },
			});
		},
	},
};
</script>

<style scoped>
.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner {
	/* 样式可以根据需求自行调整 */
	border: 4px solid rgba(255, 255, 255, 0.3);
	border-radius: 50%;
	border-top: 4px solid #fff;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
