<template>
	<div class="other_page_r">
		<el-card class="card_box">
			<div slot="header" class="card_title">
				<span>banner管理</span>
			</div>
			<div>
				<el-form :inline="true" :model="searchInfo" class="demo-form-inline">
					<el-form-item label="标题">
						<el-input
							class="custom_input_180"
							v-model="searchInfo.title"
							placeholder=""
						>
						</el-input>
					</el-form-item>
					<el-form-item label="Banner展示位">
						<el-select
							class="custom_input_180"
							v-model="searchInfo.type"
							placeholder=""
						>
							<el-option label="首页顶部banner" value="1"></el-option>
							<el-option label="专栏页顶部banner" value="2"></el-option>
							<el-option label="活动首页顶部banner" value="3"></el-option>
							<el-option label="专题顶部banner" value="4"></el-option>
							<el-option label="香港专区顶部banner" value="5"></el-option>
							<el-option label="美国专区顶部banner" value="6"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="状态">
						<el-select
							class="custom_input_120"
							v-model="searchInfo.is_show"
							placeholder=""
						>
							<el-option label="已上架" value="1"></el-option>
							<el-option label="未上架" value="0"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="searchBtn">查询</el-button>
						<el-button type="primary" @click="reset">重置</el-button>
						<el-button type="primary" @click="add">添加</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="table_box">
				<tablec :tableData="tableData"></tablec>
			</div>
			<div
				class="flex justify_content_end aligin_items_c table_footer custom_btn"
			>
				<div>
					<pagination
						v-show="total > 0"
						:total="total"
						:page.sync="searchInfo.page"
						:limit.sync="searchInfo.size"
						@pagination="getDataList"
					/>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import tablec from "./tablec.vue";
import ExportExcel from "../../../components/ExportExcel.vue";
import {
	getInformationList,
	getCategoryList,
	getLabelList,
	getBannerList,
} from "@/api/article";
import { searchMixin } from "@/utils/searchMixin.js";
export default {
	mixins: [searchMixin],
	components: { tablec, ExportExcel },
	name: "",
	data() {
		return {
			tableData: [],
			total: 0,
			categoryList: [],
			labelList: [],
			searchInfo: {
				title: "",
				is_show: "",
				type: "1",
				page: 1,
				size: 10,
			},
		};
	},
	created() {
		let _obj = JSON.parse(sessionStorage.getItem("bannerListKey_m"));
		if (_obj) {
			Object.keys(this.searchInfo).forEach((key) => {
				this.searchInfo[key] = _obj[key] || this.searchInfo[key];
			});
		}
		this.getDataList();
	},
	methods: {
		searchBtn() {
			this.searchInfo.page = 1;
			this.getDataList();
		},
		add() {
			this.$router.push({
				path: "/recommend/bannerdetail",
				query: { type: "edit" },
			});
		},
		getDataList() {
			let _data = Object.assign(this.searchInfo, {});
			sessionStorage.setItem("bannerListKey_m", JSON.stringify(_data));
			const that = this;
			getBannerList(this.searchInfo)
				.then((res) => {
					console.log(res);
					if (res.code == 200) {
						that.tableData = res.data.list;
						that.total = res.data.total;
					} else {
						that.tableData = [];
						that.total = 0;
					}
					this.loading = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped></style>
